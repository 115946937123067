export const data = {
  navbarTitle:"Csaba Békési",
  navbarSubtitle: "IT Consultant",
  currentPosition: "Senior Information Technology Consultant",
  currentEmployer: "Deloitte",
  currentLocation: "Hungary",
  aboutMe:
    "Throughout my consulting career spanning over 7 years, I had the privilege of working with diverse clients across various industries in rapidly evolving environments. My greatest motivation came from contributing to the development of digital products. I believe that my strengths, including critical thinking, insight into complex tasks and processes, teamwork, creativity, and problem-solving skills, have proven to be valuable assets in these projects.<br/><br/>Outside of my professional work, I'm committed to continuous improvement. I've completed several courses in software development, design methodologies, and various frameworks and tools.<br/><br/>My objective is to join a team where I can contribute to the creation of successful products or services.",
  languages: ["Hungarian (native)", "English (advanced in business)"],
  contants: [
    {
      link: "https://www.linkedin.com/in/csaba-bekesi/",
      icon: "./circle-linkedin-512.webp",
      title: "csaba-bekesi",
    },
    {
      link: "mailto:bekesicsaba91@gmail.com",
      icon: "./mail-icon.png",
      title: "bekesicsaba91@gmail.com",
    },
  ],
  certifications: [
    {
      link: "https://www.credly.com/badges/32bdaae6-10ac-48a2-90ff-e346a67cfae3/linked_in_profile",
      icon: "./PSM1.png",
      title: "Professional Scrum Master™ I (PSM I) (2023)",
    },
    {
      link: "https://university.atlassian.com/student/award/H5S2iNzWRpVBWavPXMaygnpT?id=194127620",
      icon: "./JIRA.png",
      title: "Jira Fundamentals (2022)",
    },
    {
      link: "https://www.credly.com/badges/4b22737a-8f4a-4526-8b22-cda7038a43ae?source=linked_in_profile",
      icon: "./azure-data-fundamentals-600x600.png",
      title: "Azure Data Fundamentals (2021)",
    },
    {
      link: "https://www.credly.com/badges/b68eacfc-1d6c-4f71-9f64-f3a5db15f0d2?source=linked_in_profile",
      icon: "./azure-cloud-fundamentals-600x600.png",
      title: "Azure Cloud Fundamentals (2020)",
    },
  ],
  jobs: [
    {
      companyName: "Deloitte",
      location: "Hungary",
      startingDate: "2019. September",
      endDate: "",
      jobTitle: "Senior Information Technology Consultant",
      description:
        "Throughout my time here, I had the privilege to explore various industries, addressing the business and technological challenges encountered by clients. I gained valuable experience in organizing and executing complex projects, achieving business and strategic objectives, managing tasks, coordinating teams, and nurturing client relationships.",
      projects: [
        {
          projectTitle: "Strategic IT Project Management and Business Support for Mobile Application Development",
          projectDuration: "7 months",
          projectRole: "IT Stream Lead, Specialist",
          projectSector: "Gambling",
          projectDescription:
            "My task involves <strong>supporting the IT side of their flagship mobile application designed for the client</strong>. This includes <strong>identifying and documenting non-functional requirements, supporting the high-level planning of the application</strong> (e.g., definition of main components, high-level architecture, or integration planning).<br/>I provide professional <strong>support to the business side, focusing on the identification of IT risks and the development of corresponding solutions</strong>. Additionally, I am responsible for <strong>providing a methodology for price comprehension</strong>, preparing tender materials such as detailed proposal breakdowns, supporting invited vendors, <strong>participating in professional Q&A sessions, conducting offer evaluations</strong>, and providing client support in decision-making.",
        },
        {
          projectTitle: "Back-Up Solution Concept Development and Risk Analysis",
          projectDuration: "3 months",
          projectRole: "Project Manager, Specialist",
          projectSector: "Insurance",
          projectDescription:
            "My role included <strong>developing the back-up system concept, conducting risk analysis with solutions </strong> for the client. I also presented technological innovations and opportunities resulting from the new solution. The document had to be comprehensible for a diverse audience, encompassing business, technology, and senior management. <strong>I developed a methodological approach to address issues and emphasize achievable results throughout the project.</strong>",
        },
        {
          projectTitle: "ERP System Selection",
          projectDuration: "6 months",
          projectRole: "Specialist",
          projectSector: "Retail",
          projectDescription:
            "I was responsible for <strong>surveying the client's customized solution, documenting technical requirements, and assisting in vendor</strong> selection by addressing technical queries and supporting demonstrations as an IT specialist.",
        },
        {
          projectTitle: "Post-Acquisition IT Carve-Out and Integration Management",
          projectDuration: "6 months",
          projectRole: "Specialist",
          projectSector: "Energy",
          projectDescription:
            "My tasks involved the <strong>identification, assessment, consolidation, and prioritization of reports</strong> within the organization, with a specific focus on ensuring legal and business continuity compliance. Additionally, I provided a <strong>professional contribution to the planning of the integrated reporting solution</strong> in the new environment.",
        },
        {
          projectTitle: "Middleware Integration Project",
          projectDuration: "19 months",
          projectRole: "Project management support and specialist",
          projectSector: "Entertainment",
          projectDescription:
            "In this role, I provided comprehensive <strong>project management support, developed integration methodologies and regulations, and prepared essential decision support documentation, reports, and specifications.</strong> I maintained <strong>communication with suppliers</strong>, offered assistance in bridging and resolving technological challenges, and <strong>contributed to the preparation of tender materials and procurement processes.</strong>",
        },
        {
            projectTitle: "Digital Transformation and License Optimization Project",
            projectDuration: "5 months",
            projectRole: "Senior Consultant",
            projectSector: "Insurance",
            projectDescription:
              "I conducted a comprehensive <strong>review of the customer's licenses, proposing changes to enhance completeness and efficiency.</strong> Additionally, I explored and documented point-to-point system connections to <strong>develop an integration map</strong>. I actively <strong>supported customer relations and project management tasks</strong>, including status tracking, task delegation, and implementation support. Furthermore, I <strong>conducted technical assessments and specified IT systems</strong>, covering infrastructure, operating environments, and their logical dependencies.",
          },
          {
            projectTitle: "Data Quality Monitoring App Development",
            projectDuration: "7 months",
            projectRole: "Specialist and developer",
            projectSector: "Insurance",
            projectDescription:
              "Involved in <strong>designing technological processes</strong> for a data quality control and cleaning tool, actively contributed to <strong>development (C#, MSSql) based on specifications</strong>, and focused on <strong>integration and performance optimization</strong> of the existing code base.",
          },
      ],
    },
    {
      companyName: "Clarity Consulting",
      location: "Hungary",
      startingDate: "2016. June",
      endDate: "2019. August",
      jobTitle: "Information Technology Consultant",
      description:
        "As a junior consultant, I had the opportunity to engage in a wide range of professionally challenging projects. This experience allowed me to acquire fundamental consulting skills, delve into diverse industries such as banking and state administration, and accumulate valuable experiences over my three years here. These experiences have made a substantial contribution to both my career and personal development.",
      projects: [
        {
          projectTitle: "Electronic Health Sector Development (EFOP-1.9.6)",
          projectDuration: "6 months",
          projectRole: "Consultant",
          projectSector: "Healthcare fund manager",
          projectDescription:
            "Responsible for <strong>preparing specifications and system plans</strong> for implementing the data warehouse, <strong>generating reports, and conducting analyses</strong> based on it. Additionally, played a key role in <strong>developing contract registration system processes</strong> and creating the necessary specifications and system plans.",
        },
        {
          projectTitle: "BI & Data Warehouse Consolidation Concept",
          projectDuration: "5 months",
          projectRole: "Consultant",
          projectSector: "Public administration",
          projectDescription:
            "Prepared a <strong>data warehouse and business intelligence (BI) concept</strong> for a public administration, including <strong>assessing data utilization practices, conducting BI trend analysis,</strong> and defining a consolidated data warehouse-BI tool system. <strong>Developed a high-level requirement specification, implementation roadmap, and budget model.</strong>",
        },
        {
            projectTitle: "ASP 2.0 System Nationwide Expansion",
            projectDuration: "6 months",
            projectRole: "Consultant",
            projectSector: "Public administration",
            projectDescription:
              "Provided <strong>expert support in complex administrative organization tasks</strong>, which included facilitating the public procurement process for data storage. Participated in <strong>system design</strong> for the data warehouse by <strong>assessing business and technological requirements</strong> through workshops and expert consultations. <strong>Developed processes and policies</strong> related to the data warehouse, including managing new business needs and assisting data warehouse developers in addressing implementation challenges with solutions collaboratively developed with the customer.",
          },
          {
            projectTitle: "Professional System Interface Unification Project",
            projectDuration: "3 months",
            projectRole: "Consultant",
            projectSector: "Public administration",
            projectDescription:
              "Contributed to an administrative organization project for a domestic public administration entity, involving the <strong>creation of a concept for unifying professional system interfaces</strong>. Conducted assessments of individual professional system interfaces, <strong>developed specifications for further enhancements, and established requirements</strong> for an interface standardization handbook for both existing and new professional systems.",
          },
          {
            projectTitle: "Hungarian Bank Supervisory System Specification",
            projectDuration: "3 months",
            projectRole: "Consultant",
            projectSector: "Bank",
            projectDescription:
              "Prepared a new IT system by <strong>identifying business and technological needs</strong> through workshops and interviews, followed by <strong>consolidating and documenting the identified requirements.</strong>",
          },
          {
            projectTitle: "Hungarian Bank Supervisory Process Support System Concept",
            projectDuration: "2 months",
            projectRole: "Consultant",
            projectSector: "Bank",
            projectDescription:
              "Prepared a new IT system by <strong>identifying business and technological needs</strong> through workshops and interviews, and <strong>documented the consolidated requirements.</strong>",
          },
      ],
    },
    {
      companyName: "Corvinus University of Budapest",
      location: "Hungary",
      startingDate: "2017",
      endDate: "2020",
      jobTitle: "Master In Leadership And Management",
      description:
        "I earned a Master's in Management and Organization, focusing on economics and management expertise. The program covered quantitative foundations, management concepts, logistics, project management, and software applications for data analysis and optimization. It also involved practical case studies and simulations in real-world scenarios.",
      projects: [],
    },
    {
      companyName: "Budapest University of Technology and Economics",
      location: "Hungary",
      startingDate: "2013",
      endDate: "2017",
      jobTitle: "Bsc in Engineering Management",
      description:
        "I completed BME's Technical Manager program, which harmoniously combines economics and technology to bridge the gap between these fields. The program provided comprehensive training in management, finance, and communication, while also keeping us updated with the latest technological insights.",
      projects: [],
    },
  ],
  projects: [
    {
      title: "CV Enhancement Platform",
      year: "2023",
      description:
        "As a consultant, condensing my references and experiences into a concise yet compelling format on my CV has always been a challenge. To address this, I have developed a platform that complements my brief and focused resume, allowing me to provide a more detailed account of my experiences. This project also serves as a representation of my IT expertise, utilizing technologies such as the React framework and Tailwind CSS.<br /><br />The platform's code prioritizes practicality, featuring a central JSON object containing all my experiences and metadata displayed on this page. By modifying this object, the interface dynamically adjusts to reflect changes. I have hosted the project on Firebase for seamless accessibility.",
      stack: [
        "React",
        "Figma",
        "Tailwind CSS",
        "Firebase"
      ],
    },
    {
      title: "Creating a Crypto Trading Platform: A Journey of Strategy and Technology",
      year: "2021",
      description:
        "I've always held a keen interest in the world of stock market investments. After a few unsuccessful trades, I engaged in self-reflection and realized that my emotions significantly influenced my decisions. As an experimental endeavor, I developed a trading platform capable of tracking crypto market changes in near real-time, leveraging Binance websockets.<br /><br />I constructed a rule engine to process incoming data, enabling the application to assess whether predefined factors, such as multiple technical indicators, aligned as expected. Transactions were executed on a virtual wallet through the interface. To enhance user experience and ease of management, I designed a fully functional web interface. This interface facilitated the configuration of rules, portfolios, their modifications, monitored currencies, and other settings.<br /><br />For the development, I utilized the .NET Core framework, implementing a microservice architecture for efficient domain management. PostgreSQL served as the chosen database, while the web interface was built using the Microsoft Blazor framework.",
      stack: [
        "C#",
        ".net core",
        "Microsoft Blazor",
        "Microservice architecture",
        "Boostrap CSS",
        "PostgreSQL",
      ],
    }
  ],
};
