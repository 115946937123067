import React from "react";
import CompanyWithLocationAndDate from "./CompanyWithLocationAndDate";
import Project from "./Project";
import TextIt from "../TextVisualizer/TextIt";

const JobExperience = ({
  companyName,
  location,
  startingDate,
  endDate,
  jobTitle,
  description,
  projects,
}) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex flex-col">
        <div className="flex-none mb-4">
          <div className="flex">
            <div className="mr-2 self-center">
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="6"
                  r="4.5"
                  fill="white"
                  stroke="#487A9F"
                  strokeWidth="3"
                />
                <line
                  x1="7.00003"
                  y1="5.5"
                  x2="3.03864e-05"
                  y2="5.5"
                  stroke="#487A9F"
                  strokeWidth="3"
                />
              </svg>
            </div>
            <div className="flex-none w-48">
              <CompanyWithLocationAndDate
                companyName={companyName}
                location={location}
                startingDate={startingDate}
                endDate={endDate}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-flow-row mr-11 ml-11">
        <div className="justify-self-start mb-2 font-bold text-xl blueColor">{jobTitle}</div>
        <div className="justify-self-start text-justify leading-tight mb-2">
          <TextIt content={description} />
        </div>
        <div className="justify-self-start">
          {projects.map((project) => (
            <Project
            key={project.projectTitle}
              projectTitle={project.projectTitle}
              projectDuration={project.projectDuration}
              projectRole={project.projectRole}
              projectSector={project.projectSector}
              projectDescription={project.projectDescription}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobExperience;
