import React from "react";
import TextIt from "../TextVisualizer/TextIt";

const PersonalProjectCard = ({title, year, description, stack}) => {

  return (
    <div className="ml-11 mr-11 pt-5 grid grid-cols-1">
        <div className="text-start font-bold blueColor">
            {title}
        </div>
        <div className="text-start font-light">
            {year}
        </div>
        <div className="text-justify font-normal leading-tight">
            <TextIt content={description} />
        </div>
        <div className="text-start font-light">
        {stack.map((element, index) => (
          <span key={element+index} className="pl-4 pr-4">{element}</span>
        ))}
        </div>
    </div>
  );
};

export default PersonalProjectCard;
