import React, { useState } from "react";
import { Spiral as Hamburger } from "hamburger-react";

const Navbar = ({ data }) => {
  const links = [
    { name: "About", link: "#about" },
    { name: "Experience", link: "#experience" },
    { name: "Projects", link: "#projects" },
  ];

  const [open, setOpen] = useState(false);

  return (
    <nav className="w-full fixed top-0 left-0 md:flex items-center justify-evenly bg-white py-4 px-6 m-0 z-40">
      <div
        className="font-bold text-2xl flex items-center font-sans
        text-gray-800"
      >
        <div className="w-32 h-[39px] relative">
          <div className="left-0 top-0 absolute text-black text-xl font-bold font-sans">
          {data.navbarTitle}
          </div>
          <div className="left-0 top-[24px] absolute text-black text-xs font-normal font-sans">
          {data.navbarSubtitle}
          </div>
        </div>
      </div>

      <div
        onClick={() => setOpen(!open)}
        className="text-sm absolute right-8 top-5 cursor-pointer md:hidden"
      >
        <Hamburger
          duration={0.3}
          easing="ease-in"
          toggled={open}
          toggle={setOpen}
        />
      </div>

      <ul
        className={`md:flex md:items-center md:justify-center md:space-x-16 md:flex-grow absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-300 ease-in-out ${
          open ? "top-20 " : "top-[-490px]"
        }`}
      >
        {links.map((link) => (
          <li
            key={link.name}
            className="text-lg md:my-0 first-of-type:mt-3 md:first-of-type:mt-0 my-7"
          >
            <a
              key={link.name}
              href={link.link}
              className={`text-gray-800 hover:text-gray-400 duration-500`}
            >
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
