import React from "react";

const Footer = () => {

    var year = new Date().getFullYear();
  return (
    <div className="mt-20 w-full grid grid-cols-1 grayBgColor">
      <div className="flex flex-row justify-center ml-10 mr-10">
        <div className="pl-4 pr-4 text-white"><a href="https://www.linkedin.com/in/csaba-bekesi/">LinkedIn</a></div>
        <div className="pl-4 pr-4 text-white"><a href="mailto:bekesicsaba91@gmail.com">Contact</a></div>
        <div className="pl-4 pr-4 text-white">{year}</div>
      </div>
    </div>
  );
};

export default Footer;
