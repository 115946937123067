import React from "react";

const CompanyWithLocationAndDate = ({companyName,location, startingDate, endDate}) => {
  return (
    <div className="flex flex-col">
      <div className="justify-self-start text-start font-bold">{companyName}</div>
      <div className="justify-self-start text-start font-light">{location}</div>
      <div className="justify-self-start text-justify font-light leading-tight">{startingDate} - <br/> {endDate}</div>
    </div>
  );
};

export default CompanyWithLocationAndDate;
