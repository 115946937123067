import "./App.css";
import Navbar from "./components/Navigation/Navbar";
import About from "./components/AboutMe/About";
import ProfessionalExperience from "./components/Experience/ProfessionalExperience";
import PersonalProjects from "./components/PersonalProjects/PersonalProjects";
import Footer from "./components/Footer/Footer";
import { data } from "./components/Data/Data";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBwO8hMbxio250BYqCHJ1ba8DNhzqkuWR0",
  authDomain: "csaba-portfolio-app.firebaseapp.com",
  projectId: "csaba-portfolio-app",
  storageBucket: "csaba-portfolio-app.appspot.com",
  messagingSenderId: "58507023215",
  appId: "1:58507023215:web:8fd23a4689c17379cb8e9a",
  measurementId: "G-N3610J96ZL"
};

function App() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return (
    <div className="App">
      <Navbar data={data}/>
      <About data={data} />
      <div className="grid grid-cols-1 justify-self-center mx-auto max-w-[1000px]">
        <div>
          <ProfessionalExperience jobs={data.jobs} />
          <PersonalProjects projects={data.projects} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
