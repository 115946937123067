import React from "react";
import JobExperience from "../JobExperience/JobExperience";

const ProfessionalExperience = ({ jobs }) => {
  return (
    <div
      id="experience"
      className="pt-5 min-h-[700px] w-max-[400px] grid grid-cols-1"
    >
      <span className="blueColor font-bold justify-self-start ml-5 text-2xl">
        Professional Experience
      </span>
      <div className="flex">
        <div className="timeline ml-2 md:ml-10 flex-none"></div>
        <div className="flex-initial mt-4">
          {jobs.map((job) => (
            <JobExperience
              key={job.title+job.endDate}
              companyName={job.companyName}
              jobTitle={job.jobTitle}
              description={job.description}
              endDate={job.endDate}
              startingDate={job.startingDate}
              location={job.location}
              projects={job.projects}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfessionalExperience;
