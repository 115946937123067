import React from "react";
import TextIt from "../TextVisualizer/TextIt";

const Project = ({
  projectTitle,
  projectDuration,
  projectRole,
  projectSector,
  projectDescription,
}) => {
  return (
    <div className="flex flex-col ml-2 pb-3">
      <div className="flex flex-col pb-1">
        <div className="flex flex-col md:flex-row">
          <div className="blueColor font-bold text-justify pl-1 pr-1">
            {projectTitle}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="font-normal text-justify /*md:border-r-[1px] border-[#487A9F]*/ pl-1 pr-1 flex">
            {/*<img className="w-[20px] h-[20px] ml-1 mr-1" src="./role.png" />*/}
            
            {projectRole}
          </div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="font-normal text-justify /*md:border-r-[1px] border-[#487A9F]*/ pl-1 pr-1 flex">
            {/*<img className="w-[20px] h-[20px] ml-1 mr-1" src="./sector.png" />*/}
            <span className="mr-1">Industry:</span>{projectSector}
          </div>
          <div className="font-normal text-justify pl-1 pr-1 flex">
            {/*<img className="w-[20px] h-[20px] ml-1 mr-1" src="./duration.png" />*/}
            <span className="mr-1">Duration:</span>{projectDuration}
          </div>
        </div>
      </div>
      <div className="justify-self-start text-justify leading-tight mb-4 pl-1">
        <TextIt content={projectDescription} />
      </div>
    </div>
  );
};

export default Project;
