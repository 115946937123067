import React from "react";
import PersonalProjectCard from "./PersonalProjectCard";

const PersonalProjects = ({projects}) => {
  return (
    <div id="projects" className="pt-5 min-h-700 w-full grid grid-cols-1">
      <span className="blueColor font-bold justify-self-start ml-5 text-2xl">
        Personal projects
      </span>
      <div className="flex flex-col">
        {projects.map((project, index) => (
          <PersonalProjectCard key={project.title+index} title={project.title} year={project.year} description={project.description} stack={project.stack}/>
        ))}
      </div>
    </div>
  );
};

export default PersonalProjects;
