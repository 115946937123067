import React from "react";

const ListElement = ({ link, icon, title }) => {
  return (
    <>
      {link === "" ? (
        <div key={title} className="text-white flex justify-start mt-1">
          {icon === "" ? "" : <img src={icon} className="listIcon" alt="List icon" />}
          <span className="ml-2">{title}</span>
          <br />
        </div>
      ) : (
        <a href={link}>
          <div key={title} className="text-white flex justify-start mt-1">
            {icon === "" ? "" : <img src={icon} className="listIcon" alt="List icon"/>}
            <span className="ml-2 break-all">{title}</span>
            <br />
          </div>
        </a>
      )}
    </>
  );
};

export default ListElement;
