import React from "react";
import ListElement from "../ListElement/ListElement";
import TextIt from "../TextVisualizer/TextIt";

const About = ({ data }) => {
  return (
    <div
      id="about"
      className="grid grid-cols-1 w-full grayBgColor min-h-700 justify-self-center"
    >
      <div className="mx-auto pt-20 grid grid-cols-1 md:grid-cols-3 max-w-[1000px]">
        <div className="justify-self-center md:justify-self-end self-center">
          <div className="mr-3 ml-3 mt-2 mb-2">
            <img
              className="max-w-[300px] max-h-[300px] w-[90%] h-[90%] rounded-full border-2 border-white"
              src="./profile.jpg"
              alt="This is me"
            />
            <div className="text-white font-bold text-2xl w-[90%]">Csaba Békési</div>
          </div>
        </div>
        <div className="justify-self-start text-left mr-5 md:mr-11 mt-2 mb-2 ml-4 md:ml-1 col-span-2">
          <div className="justify-self-start">
            <span className="blueColor text-2xl font-bold leading-normal">
              {data.currentPosition}
            </span>
            <br />
            <span className="blueColor text-slate-500 font-normal leading-tight">
              @{data.currentEmployer} - {data.currentLocation}
            </span>
            <br />
            <br />
            <span className="text-white mb-1 leading-tight text-justify">
              <TextIt content={data.aboutMe} />
            </span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="w-50 mt-1">
              <span className="blueColor text-slate-500 font-bold leading-normal pt-5">
                Languages
              </span>
              <br />
              {data.languages.map((language) => (
                <ListElement key={language} link="" title={language} icon="" />
              ))}
              <br />
              <span className="blueColor text-slate-500 font-bold leading-normal pt-1">
                Contacts
              </span>
              <br />
              {data.contants.map((contact) => (
                <ListElement
                  key={contact.title}
                  link={contact.link}
                  title={contact.title}
                  icon={contact.icon}
                />
              ))}
              <br />
            </div>
            <div className="w-50 mt-1">
              <span className="blueColor text-slate-500 font-bold leading-normal pt-5">
                Certifications
              </span>
              <br />
              {data.certifications.map((certification) => (
                <ListElement
                  key={certification.title}
                  link={certification.link}
                  title={certification.title}
                  icon={certification.icon}
                />
              ))}
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
